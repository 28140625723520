import React from "react";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Link,
  Backdrop,
  CircularProgress,
  Tab,
  Tabs,
  Card,
  CardHeader
} from "@mui/material";
import homeBanner from "../../images/Botmark-Banner-Design-With-Logos.jpg";
import DeanPhoto from "../../images/Dean-Photo.jpeg";
import ArnelPhoto from "../../images/Arnel-Photo.jpeg";
import Youtube from 'react-youtube';

function HomeTutorial(){
    const opts = {
        height: '425px',
        width: '100%',
    }
    return(
        <div>
            <Card style={{ border: '1px solid black', boxShadow:" 1px 4px 8px rgba(0, 0, 0, 0.2)", width:"50%", height:"500px", margin:"auto", marginTop:"20px", backgroundColor:"#212121"}}>
                <CardHeader style={{height:'40px', color:"white"}} title={"Demonstration"}/>
                <Youtube videoId={"bR9IOmSCbQ8"} opts={opts} />
            </Card>
        </div>
    )
}

export default HomeTutorial;